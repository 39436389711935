<template>
  <div class="dislocation">
    <h1 style="padding-top: 50px">///</h1>
  </div>
</template>


<script>
export default{
  name: 'dislocation'
}
</script>

