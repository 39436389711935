var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"none"}},[_vm._m(0),_c('div',{staticClass:"news"},[_c('div',{staticClass:"news__content",on:{"click":function($event){return _vm.OpenPage1()}}},[_vm._m(1),_c('div',{staticClass:"news__content__body"},[_vm._v(" Идея покупки инновационных вагонов – цистерн возникла в компании еще летом 2020 года в связи с наличием у данного подвижного состава трех важных ... ")]),_vm._m(2)]),_c('div',{staticClass:"news__content",on:{"click":function($event){return _vm.OpenPage2()}}},[_vm._m(3),_c('div',{staticClass:"news__content__body"},[_vm._v(" Заместитель министра транспорта РФ Кирилл Богданов 18 апреля 2022 года был освобожден от должности, об этом сообщается в опубликованном ... ")]),_vm._m(4)]),_c('div',{staticClass:"news__content",on:{"click":function($event){return _vm.OpenPage3()}}},[_vm._m(5),_c('div',{staticClass:"news__content__body"},[_vm._v("Отгрузки топлива планируется начать в конце августа 2022 года. Проект связан с освоением Сыллахского месторождения угля, расположенного на ... ")]),_vm._m(6)]),_c('div',{staticClass:"news__content",on:{"click":function($event){return _vm.OpenPage4()}}},[_vm._m(7),_c('div',{staticClass:"news__content__body"},[_vm._v("ООО «Транспортные Технологии» — на сегодняшний день одна из самых динамично развивающихся компаний на рынке ... ")]),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prevue"},[_c('h4',[_vm._v("Новости железнодорожного транспорта")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news__content__header"},[_vm._v("20.04.2022 в 15:47 "),_c('div',{staticClass:"triangle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news__content__footer"},[_c('button',[_vm._v("Читать далее ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news__content__header"},[_vm._v("20.04.2022 в 15:35 "),_c('div',{staticClass:"triangle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news__content__footer"},[_c('button',[_vm._v("Читать далее ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news__content__header"},[_vm._v("20.04.2022 в 15:31 "),_c('div',{staticClass:"triangle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news__content__footer"},[_c('button',[_vm._v("Читать далее ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news__content__header"},[_vm._v("27.10.2021 в 10:40 "),_c('div',{staticClass:"triangle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news__content__footer"},[_c('button',[_vm._v("Читать далее ")])])
}]

export { render, staticRenderFns }