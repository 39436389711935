<template>
    <div>
        <div class="news">
       <div class="news__content">
           <div class="news__content__header">ИТОГИ ЗА 9 МЕСЯЦЕВ 2021 ГОДА</div>
           <div class="news__content__body">ООО «Транспортные Технологии» — на сегодняшний день одна из самых динамично развивающихся компаний на рынке предоставления железнодорожного подвижного состава для перевозки широкой номенклатуры грузов. <br>
Предыдущий 2020 год стал тяжелым испытанием для бизнеса. Рынку транспортных услуг в этот год пришлось столкнуться с серьезными и порой незнакомыми ранее вызовами. Снижение товарооборота на железнодорожной сети, уход с рынка мелких игроков, миллиардные убытки, простои и снижение спроса на экспортную сырьевую продукцию российских предприятий, снижение ставок предоставления подвижного состава – вот неполный список проблем, выпавших на долю участников рынка железнодорожных перевозок.

<br>Несмотря на все трудности компания ООО «Транспортные Технологии», благодаря высокому профессионализму менеджмента, а также реализации большинства запланированных комплексных транспортных проектов, смогла не только справиться с этими вызовами, но и увеличить свое присутствие на рынке предоставления железнодорожных услуг.
<br>Так с начала 2021 года был реализован проект по увеличению парка как собственных, так и арендованных вагонов, который по состоянию на начало 2021 года составлял 9796 единиц подвижного состава:
<br>* Приобретено в собственность 400 вагонов, 95% из которых люковые полувагоны;
<br>* На завершающей фазе приобретение в собственность 250 инновационных нефтехимических цистерн;
<br>* Реализуется увеличение кол-ва арендуемого универсального парка на 1600 люковых полувагонов до конца 2021 года;
<br>* Реализуется увеличение кол-ва арендуемого парка нефтехимических цистерн на 400 вагонов до конца 2021 года.
<br>В конце сентября 2021 года ООО «Транспортные Технологии» подписало договор на оказание услуг по сервисному обслуживанию грузовых вагонов с ООО «Новая вагоноремонтная компания» сроком на 3 года, без права досрочного расторжения и с 01.10.2021 г. передало на «сервис» около 5 000 грузовых вагонов.
<br>Как прокомментировали в ООО «Транспортные Технологии», основными эффектами, которые компания ожидает от данного контракта, являются: фиксация стоимости технического содержания парка грузовых вагонов в эксплуатации, как следствие минимизации рисков роста стоимости такого содержания в периоде 3 лет; сокращение простоя парка вагонов за счет того, что парк будет под «присмотром» компании, в которой работают более 9 000 профессионалов в области вагонного хозяйства, когда решение о ремонте и влияние на различные факторы, являющиеся на сегодня основными причинам простоя вагона в ожидании ремонта, будет приниматься максимально оперативно и непосредственно «у вагона»; повышение качества ремонта подвижного состава, так как ООО «Новая вагоноремонтная компания» уже напрямую заинтересована в исключении случаев отцепки вагонов в текущий ремонт, так как в рамках договора на сервисное обслуживание, любой текущий ремонт является прямыми издержками, которые снижают доходность в рамках данной деятельности.
<br>Так же, в компании отметили, что при реализации всех эффектов данного договора, услуга сервисного обслуживания может стать хорошим драйвером для решения наболевших проблем в вопросах роста отцепок в текущий ремонт, так как вагоноремонтные компании должны существенно усилить контроль за качеством производства плановых видов ремонта, с целью минимизации отцепок в текущий ремонт, а также принять непосредственное участие в разборе случаев отцепок в данные виды ремонта, с детальным разбором причин отцепки и принятия необходимых мер по ликвидации причин, повлекших отвлечение вагонов от перевозочного процесса. Данные мероприятия позволят получить синергетический эффект на всём периоде перевозочного процесса и повысить эффективность работы вагоноремонтной отрасли.
<br><br>
Во 2-м квартале 2021 года ТТ подписали сервисный контракт с компанией «Металлоинвест» (один из крупнейших горно-металлургических холдингов России и один из ведущих мировых производителей железорудной продукции). Это позволит обеспечить стабильный вывоз ЖРС и готовой продукции с предприятий холдинга «Металлоинвест» в экспортных направлениях. Одним из важнейших конкурентных преимуществ работы парка ТТ в рамках указанного договора является подача порожних полувагонов на активы Холдинга — горнорудные предприятия (Лебединский и Михайловский ГОКи) и металлургические предприятия (Оскольский электрометаллургический комбинат) — отобранными, качественными ,сформированными маршрутами (менеджмент ТТ разработал и реализовал специальное технологическое решение) . Это позволяет значительно повысить эффективность работы жд цехов предприятий и снять нагрузку с инфраструктуры подъездных путей необщего пользования. Металлоинвест и ТТ расширяют рамки сотрудничества и на 4-й квартал 2021 г. согласовано увеличение подачи парка ТТ более чем на 30% (по сравнению с 3-м кварталом).
<br><br>
В целях реализации финансовой стратегии бизнеса Компании,
во 2-м квартале 2021 г АО «Альфа-банк» была открыта кредитная линия на 1,1 млрд. руб.;
<br>В 3-м квартале  2021 г ПАО «ВТБ» была открыта инвестиционная кредитная линия в размере 4,0 млрд. руб. В рамках данного лимита была реализована
сделка возвратного лизинга на сумму более 1,3 млрд. руб.

</div>
       </div>
      
    </div>
      <ContactForm style="margin-top: 7%;"/>
      <Footer />
    </div>

</template>

<style scoped>
.news{
    width: 60%;
    position: relative;
    left:50%;
    transform: translate(-50%,0);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    padding: 5%;
    margin-top: 5%;
}

.news__content__header {
    color: #EC2332;  
  display: flex;
  justify-content: start;
  margin-bottom: 3%;
  font-weight: 700;
  font-size: 18px;
}
.news__content__body {
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}
@media screen and (max-width: 1600px){
    .news{
        width:90%;
        margin-top: 10%;
    }
  
    }
    @media screen and (max-width: 600px){
        .news{
        margin-top: 20%;
    }
}
</style>


<script>
import ContactForm from '@/components/ContactForm.vue';
import Footer from '@/components/Footer.vue'
export default{
    components: {  ContactForm, Footer },

}
</script>