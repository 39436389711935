<template>
    <div>
        <div class="news">
       <div class="news__content">
           <div class="news__content__header">ООО «ТРАНСПОРТНЫЕ ТЕХНОЛОГИИ» В НЕПРОСТЫХ ЭКОНОМИЧЕСКИХ УСЛОВИЯХ ОБНОВЛЯЕТ СОБСТВЕННЫЙ ПАРК ИННОВАЦИОННЫМИ ВАГОНАМИ-ЦИСТЕРНАМИ.</div>
           <div class="news__content__body">
            Идея покупки инновационных вагонов – цистерн возникла в компании еще летом 2020 года в связи с наличием у данного подвижного состава трех важных конкурентных составляющих: цена – качество – доходность.

Однако окончательное решение было принято только в конце 2021 года. <br>
<br>
Начиная с октября 2021 года между ООО «Транспортные Технологии», ООО «Торговый Дом «ОВК», ООО «Открытый Лизинг» проводилась работа по согласованию условий реализации нового проекта – приобретение инновационных вагонов-цистерн производства АО «Тихвинский вагоностроительный завод».
<br>
Выбор был остановлен на вагон – цистерне модели 15 – 9993, которая имеет существенные коммерческие отличия от типовых вагонов цистерн:
<br> <br>
Увеличенный объем котла вагона-цистерны 88 м3 и грузоподъемность 73,3 тонн позволяют перевозить все виды нефтеналивных грузов плотностью при наливе до 1,04 т/м3, что дает ощутимый экономический эффект. По сравнению с типовым вагоном-аналогом погрузка в цистерну модели 15-9993 увеличена на 4 т и составляет 70 т. Конструкция котла с ломаной осью обеспечивает полноту слива. Применение полимерных материалов, не подверженных влиянию окружающей среды, на контактных поверхностях лежневых опор котла обеспечивает улучшенные показатели надежности и безопасности вагона-цистерны. Увеличенные до 1 000 000 км (или 8 лет) межремонтные пробеги обеспечивают снижение стоимости жизненного цикла вагона-цистерны почти в 3 раза.
<br> <br>
Со стороны ООО «Транспортные Технологии», учитывая особенности конструкции вагонов — цистерн и существующие технические условия налива и слива перевозимого груза на эстакадах, в адрес АО «Тихвинский вагоностроительный завод» были предъявлен ряд требований по адаптации конструкции ряда элементов кузова для захода цистерны на сливо-наливные эстакады.
<br>
Данные требования со стороны АО «Тихвинский вагоностроительный завод» были приняты и учтены при строительстве вагонов – цистерн.
<br> <br>
И вот, первая партия 50 инновационных вагонов – цистерн модели 15-9993 с логотипом ООО «Транспортные Технологии» была предъявлена к технической приемке специалистам компании 13.04.2022 года.
<br> <br>
Приемка первой партии вагонов – цистерн прошла успешно и в ближайшее время данные вагоны отправятся под свою первую погрузку на Куйбышевскую ж.д.

 
           </div>
         
       </div>

   </div>
      <ContactForm style="margin-top: 7%;"/>
      <Footer />
    </div>

</template>

<style scoped>
.news{
    width: 60%;
    position: relative;
    left:50%;
    transform: translate(-50%,0);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    padding: 5%;
    margin-top: 5%;
}

.news__content__header {
    color: #EC2332;  
  display: flex;
  justify-content: start;
  margin-bottom: 3%;
  font-weight: 700;
  font-size: 18px;
}
.news__content__body {
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}
@media screen and (max-width: 1600px){
    .news{
        width:90%;
        margin-top: 10%;
    }
  
    }
    @media screen and (max-width: 600px){
        .news{
        margin-top: 20%;
    }
}
</style>


<script>
import ContactForm from '@/components/ContactForm.vue';
import Footer from '@/components/Footer.vue'
export default{
    components: {  ContactForm, Footer },

}
</script>