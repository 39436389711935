<template>
    <div>
        <div class="row mr-0" style="background: #171717" id="contact">
            <div class="col-md-6 mr-0">
                <img src="@/assets/map.png" alt="" style="width:90%; height: 70%; margin-left: 7%; margin-top: 7%;">
            </div>
            <div class="col-md-6 mr-0 right-contact">
                <h2 style="padding-left: 7%;"><span style="border-bottom: 3px solid #EB272B;">Контакты</span></h2>
                <h4 style="padding-left: 7%; padding-top: 2%;">Секретарь: <a href="tel:+7 (495)363-15-20"
                        style="color: white; outline: none !important;">+7 (495) 363-15-20</a></h4>
                <h4 style="padding-left: 7%; padding-top: 2%;">E-mail: <span
                        style="color: #ff5154;">info@tehtrans.com</span></h4>
                <p style="padding-left: 7%; padding-top: 2%;">
                    Диспетчер по работе с цистернами <br>
                    Рабочий: <a href="tel:+7 (495) 363-15-20" style="color: white; outline: none !important;">+7 (495)
                    363-15-20</a>, доб. 109 <br>
                    Мобильный:
                    <a href="tel:+7 (903)-565-00-91" style="color: white; outline: none !important;">+7 (903) 565-00-91</a>
                    <br>
                    e-mail: <span
                        style="color: #ff5154;"> disp@tehtrans.com </span><br>
                </p>

                <p style="padding-left: 7%; padding-top: 2%;">
                    Диспетчер по работе с полувагонами <br>
                    Рабочий: <a href="tel:+7 (495) 363-15-20" style="color: white; outline: none !important;">+7 (495)
                    363-15-20</a>, доб. 130 <br>
                    Мобильный:
                    <a href="tel:+7 (929) 592-29-66" style="color: white; outline: none !important;">+7 (929) 592-29-66</a>
                    <br>
                    e-mail: <span
                        style="color: #ff5154;"> disp2@tehtrans.com </span><br>
                </p>


                <p style="padding-left: 7%; padding-top: 2%;"> 143026, Московская область, г.о. Одинцовский, рп
                    Новоивановское, шоссе Можайское, влд. 165, БЦ «Premium West»</p>
                <p style="padding-left: 7%; padding-top: 2%;">Дополнительный офис:
                    121165, Москва, Кутузовский проспект, 30</p>
                <p style="padding-left: 7%; padding-top: 2%;">Филиал ООО Транспортные Технологии (г. Набережные Челны и
                    г. Нижнекамск) <br>
                    423800, РТ, г. Набережные Челны, ул. Товарная, д.11 <br>
                    Руководитель: Шавшуков Виталий Алексеевич
                </p>
                <p style="padding-left: 7%; padding-top: 2%;">
                    Филиал ООО "Транспортные Технологии" (г. Кемерово) <br>
                    650066, г. Кемерово, пр.Октябрьский, д. 4, офис 102, <br>
                    Руководитель: Петрачков Денис Викторович<br>

                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactForm',

}
</script>

<style>
.right-contact {
    background: #171717;
    color: white;
    padding-top: 5%;
}

.right-contact p {
    font-size: 20px;
}
</style>